// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBeO9_f5wbanzYKdk_94tVWb0pM7B5iAXw",
    authDomain: "examenes-autoescuela-2024.firebaseapp.com",
    projectId: "examenes-autoescuela-2024",
    storageBucket: "examenes-autoescuela-2024.appspot.com",
    messagingSenderId: "1061706351737",
    appId: "1:1061706351737:web:d859e89ec8cdda184c80a8",
    measurementId: "G-HR3M5B06QV"
};

// Inicializa la app de Firebase
const app = initializeApp(firebaseConfig);

// Exporta la instancia de autenticación
export const auth = getAuth(app);
