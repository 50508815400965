import React, { useEffect, useState } from 'react';
import apiClient from './apiClient';
import moment from 'moment';

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisibleId, setLastVisibleId] = useState(null);
  const [emailSearch, setEmailSearch] = useState(''); // Search by email
  const [emailMarketingOptIn, setEmailMarketingOptIn] = useState(false); // Checkbox filter
  const [hasNextPage, setHasNextPage] = useState(true);

  const limit = 100;
  const apiUrl = process.env.REACT_APP_GET_USERS_URL;

  const fetchUsers = async (cursor = null, append = false) => {
    setLoading(true);

    try {
      // Build query parameters
      const query = new URLSearchParams({
        limit,
        ...(emailSearch && { email: emailSearch }), // Include email only if it is set
        emailMarketingOptIn, // Checkbox value
      });

      if (cursor) query.append('lastVisibleId', cursor);

      // Call the API and extract the data
      const response = await apiClient(`${apiUrl}?${query.toString()}`);
      const data = response.data; // Adjust for updated apiClient

      // Update state
      setUsers((prev) => (append ? [...prev, ...data.users] : data.users));
      setLastVisibleId(data.lastVisibleId || null);
      setHasNextPage(!!data.lastVisibleId);
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch users on filter change
    fetchUsers(null, false);
  }, [emailSearch, emailMarketingOptIn]);

  const handleEmailSearch = (e) => {
    setEmailSearch(e.target.value);
    setLastVisibleId(null); // Reset pagination on search
  };

  const toggleEmailMarketingOptIn = () => {
    setEmailMarketingOptIn((prev) => !prev);
    setLastVisibleId(null); // Reset pagination on filter toggle
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Lista de Usuarios</h2>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-6">
        <div className="flex-1">
          <label className="block text-gray-700 font-medium mb-2">Buscar por Email</label>
          <input
            type="text"
            value={emailSearch}
            onChange={handleEmailSearch}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Escribe un email"
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700 font-medium mb-2">
            Filtrar por Opt-In de Email Marketing
          </label>
          <input
            type="checkbox"
            checked={emailMarketingOptIn}
            onChange={toggleEmailMarketingOptIn}
            className="mr-2"
          />
          Mostrar solo usuarios que optaron por Email Marketing
        </div>
      </div>

      {/* User Table */}
      <div className="overflow-x-auto">
        {loading ? (
          <p className="text-center text-gray-500">Cargando usuarios...</p>
        ) : users.length === 0 ? (
          <p className="text-center text-gray-500">No se encontraron usuarios.</p>
        ) : (
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Email</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Fecha de Registro</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{user.name || 'Sin nombre'}</td>
                  <td className="py-2 px-4">{user.email || 'Sin email'}</td>
                  <td className="py-2 px-4">
                    {user.createdAt && user.createdAt._seconds
                      ? moment.unix(user.createdAt._seconds).format('DD/MM/YYYY') // Format date
                      : 'Sin fecha'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Load More Button */}
      {!emailSearch && hasNextPage && (
        <div className="flex justify-center mt-6">
          <button
            onClick={() => fetchUsers(lastVisibleId, true)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Cargar más
          </button>
        </div>
      )}
    </div>
  );
};

export default UserTable;
