import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../apiClient';

const QuestionsTable = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisibleId, setLastVisibleId] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true); // Tracks if there are more pages
  const [permissionFilter, setPermissionFilter] = useState('');
  const [temaryFilter, setTemaryFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_GET_QUESTIONS_URL;

  const limit = 50; // Number of questions per page

  // Fetch questions from the server with filters and cursor-based pagination
  const fetchQuestions = async (cursor = null, append = false) => {
    setLoading(true);
  
    try {
      // Build query parameters only if they are defined
      const query = new URLSearchParams({
        limit: limit.toString(), // Always include limit
      });
  
      if (permissionFilter) {
        query.append('permission', permissionFilter);
      }
      if (temaryFilter) {
        query.append('temary', temaryFilter);
      }
      if (searchQuery) {
        query.append('search', searchQuery);
      }
      if (cursor) {
        query.append('lastVisibleId', cursor);
      }
  
      // Log the API request for debugging
      console.log('API Request URL:', `${apiUrl}?${query.toString()}`);
  
      // Call the API and extract the response data
      const response = await apiClient(`${apiUrl}?${query.toString()}`);
      const data = response.data; // Extract data from Axios response
      console.log('API Response:', data);
  
      // Update questions and cursor state
      setQuestions((prev) => (append ? [...prev, ...data.questions] : data.questions));
      setLastVisibleId(data.lastVisibleId);
      setHasNextPage(!!data.lastVisibleId); // If lastVisibleId is null, no more pages
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  // Fetch questions whenever filters change
  useEffect(() => {
    fetchQuestions(null, false); // Reset the list on filter change
  }, [permissionFilter, temaryFilter, searchQuery]);

  // Navigate to the edit page for a question
  const editQuestion = (id) => {
    navigate(`/questions/edit/${id}`);
  };

  // Delete a question
  const deleteQuestion = async (id) => {
    const confirm = window.confirm('¿Estás seguro de que deseas eliminar esta pregunta?');
    if (!confirm) return;

    try {
      await apiClient(`https://deletequestion-6q2qzw6t4q-uc.a.run.app/${id}`, {
        method: 'DELETE',
      });
      alert('Pregunta eliminada correctamente.');
      fetchQuestions(null, false); // Reload questions after deletion
    } catch (error) {
      console.error('Error deleting question:', error);
      alert('Error al eliminar la pregunta.');
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Listado de Preguntas</h2>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-6">
        <div className="flex-1">
          <label className="block text-gray-700 font-medium mb-2">Filtrar por Permiso</label>
          <select
            value={permissionFilter}
            onChange={(e) => setPermissionFilter(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Todos</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div className="flex-1">
          <label className="block text-gray-700 font-medium mb-2">Filtrar por Temario</label>
          <input
            type="text"
            value={temaryFilter}
            onChange={(e) => setTemaryFilter(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Escribe un temario"
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700 font-medium mb-2">Buscar por Pregunta</label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Buscar pregunta"
          />
        </div>
      </div>

      {/* Questions Table */}
      <div className="overflow-x-auto">
        {loading ? (
          <p className="text-center text-gray-500">Cargando preguntas...</p>
        ) : questions.length === 0 ? (
          <p className="text-center text-gray-500">No se encontraron preguntas.</p>
        ) : (
          <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Texto de la Pregunta</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Permiso</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Temario</th>
                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => (
                <tr key={question.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{question.questionText}</td>
                  <td className="py-2 px-4">{question.permission}</td>
                  <td className="py-2 px-4">{question.temary}</td>
                  <td className="py-2 px-4 text-center">
                    <button
                      onClick={() => editQuestion(question.id)}
                      className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 mr-2"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => deleteQuestion(question.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Buttons */}
      {hasNextPage && (
        <div className="flex justify-center mt-6">
          <button
            onClick={() => fetchQuestions(lastVisibleId, true)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Cargar más
          </button>
        </div>
      )}
    </div>
  );
};

export default QuestionsTable;
