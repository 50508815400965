import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import apiClient from './apiClient';

const MetricsTable = () => {
  const [userMetrics, setUserMetrics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiClient('https://getusermetrics-6q2qzw6t4q-uc.a.run.app')
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener las métricas de usuario');
        }
        return response.json();
      })
      .then(data => {
        setUserMetrics(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  // Definir las columnas usando useMemo para optimización
  const columns = useMemo(
    () => [
      { Header: 'ID de Usuario', accessor: 'userId' },
      { Header: 'Total de Tests', accessor: 'total' },
      { Header: 'Aprobados', accessor: 'passed' },
      { Header: 'Suspendidos', accessor: 'failed' },
    ],
    []
  );

  // Usar useTable para definir la tabla
  const tableInstance = useTable({ columns, data: userMetrics });

  // Extraer las propiedades necesarias desde tableInstance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) {
    return <p className="text-center text-gray-500">Cargando métricas de usuario...</p>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Métricas de Usuario</h2>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100 border-b">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="py-2 px-4 text-left font-semibold text-gray-700"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-b hover:bg-gray-50">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="py-2 px-4">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MetricsTable;
