import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import apiClient from './apiClient';

const TestsTable = () => {
  const [reportedQuestions, setReportedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para obtener las preguntas reportadas
  useEffect(() => {
    apiClient('http://localhost:3001/api/reportedQuestions') // Cambia la URL si tu backend está en otro servidor
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener las preguntas reportadas');
        }
        
        return response.json();
      })
      .then((data) => {
        setReportedQuestions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  // Definir las columnas usando useMemo para optimización
  const columns = useMemo(
    () => [
      {
        Header: 'Texto de la Pregunta',
        accessor: 'question.questionText',
        Cell: ({ value }) => value || 'N/A',
      },
      {
        Header: 'Imagen',
        accessor: 'question.imageUrl',
        Cell: ({ value }) =>
          value ? (
            <img
              src={value}
              alt="Pregunta"
              className="w-24 h-auto rounded-md"
            />
          ) : (
            'N/A'
          ),
      },
      {
        Header: 'Fecha de Reporte',
        accessor: 'reportedAt',
        Cell: ({ value }) =>
          value ? moment(value._seconds * 1000).format('DD/MM/YYYY') : 'N/A',
      },
      {
        Header: 'Respuestas',
        accessor: 'question.answers',
        Cell: ({ value }) => (
          <ul className="list-disc list-inside">
            {value
              ? value.map((answer, index) => (
                  <li
                    key={index}
                    className={answer.correct ? 'text-green-600' : 'text-gray-800'}
                  >
                    {answer.answerText || 'N/A'}
                  </li>
                ))
              : 'N/A'}
          </ul>
        ),
      },
      {
        Header: 'Reportado Por',
        accessor: 'reportedBy',
        Cell: ({ value }) => value || 'N/A',
      },
    ],
    []
  );

  // Usar useTable para definir la tabla
  const tableInstance = useTable({ columns, data: reportedQuestions });

  // Extraer las propiedades necesarias desde tableInstance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) {
    return <p className="text-center text-gray-500">Cargando preguntas reportadas...</p>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Preguntas Reportadas</h2>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100 border-b">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="py-2 px-4 text-left font-semibold text-gray-700"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-b hover:bg-gray-50">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="py-2 px-4">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestsTable;
