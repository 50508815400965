import React, { useState } from 'react';
import apiClient from '../apiClient';

const UploadQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [permission, setPermission] = useState('');
  const [temary, setTemary] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_CREATE_QUESTION_URL;

  // Leer y parsear el archivo JSON arrastrado
  const handleFileDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer?.files[0];
    if (!file || file.type !== 'application/json') {
      alert('Por favor arrastra un archivo JSON válido.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const parsedQuestions = JSON.parse(e.target.result);
        if (!Array.isArray(parsedQuestions)) {
          throw new Error('El archivo JSON debe contener un array de preguntas.');
        }
        setQuestions(parsedQuestions);
        // alert('Archivo JSON cargado correctamente.');
      } catch (error) {
        alert('Error al leer el archivo JSON: ' + error.message);
      }
    };
    reader.readAsText(file);
  };

  // Prevenir comportamiento por defecto del navegador al arrastrar un archivo
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Enviar las preguntas al backend
  const uploadQuestions = async () => {
    if (!permission || !temary) {
      alert('Por favor selecciona un permiso y escribe un temario.');
      return;
    }

    if (questions.length === 0) {
      alert('No hay preguntas para cargar.');
      return;
    }

    setLoading(true);

    try {
      const promises = questions.map((question) => {
        const payload = {
          ...question,
          permission,
          temary,
        };

        return apiClient(apiUrl, {
          method: 'POST',
          data: payload, // Use `data` for Axios request body
        });
      });

      const responses = await Promise.all(promises);

      // Handle success and failure responses
      const failed = responses.filter((response) => response.status !== 201);

      failed.forEach((f) => console.log(f.status)); // Log failed statuses
      if (failed.length > 0) {
        alert(`${failed.length} preguntas no se pudieron cargar.`);
      } else {
        alert('Todas las preguntas se cargaron correctamente.');
      }

      setQuestions([]); // Clear questions
    } catch (error) {
      console.error('Error al cargar preguntas:', error);
      alert('Ocurrió un error al cargar las preguntas.');
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Cargar Preguntas desde un Archivo JSON</h2>

      <div
        onDrop={handleFileDrop}
        onDragOver={preventDefaults}
        onDragEnter={preventDefaults}
        onDragLeave={preventDefaults}
        className="border-2 border-dashed border-gray-300 rounded-md p-6 text-center hover:border-blue-500 cursor-pointer"
      >
        <p className="text-gray-500">
          Arrastra y suelta un archivo JSON aquí, o haz clic para seleccionar un archivo.
        </p>
      </div>

      <div className="mt-4">
        <label className="block text-gray-700 font-medium mb-2">Permiso</label>
        <select
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2"
        >
          <option value="">Selecciona un permiso</option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>
      </div>

      <div className="mt-4">
        <label className="block text-gray-700 font-medium mb-2">Temario</label>
        <input
          type="text"
          value={temary}
          onChange={(e) => setTemary(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2"
          placeholder="Escribe el temario"
        />
      </div>

      {questions.length > 0 && (
        <div className="mt-4">
          <p className="text-gray-700">
            <strong>{questions.length}</strong> preguntas cargadas del archivo JSON.
          </p>
        </div>
      )}

      <button
        onClick={uploadQuestions}
        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mt-4"
        disabled={loading}
      >
        {loading ? 'Cargando preguntas...' : 'Cargar Preguntas'}
      </button>
    </div>
  );
};

export default UploadQuestions;
