import axios from 'axios';
import { auth } from './firebaseConfig';

// Function to dynamically configure Axios
const apiClient = async (url, options = {}) => {
  try {
    // Ensure the user is authenticated
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Usuario no autenticado');
    }

    // Get the Firebase auth token
    const token = await user.getIdToken();

    // Set headers with Authorization and Content-Type
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...options.headers, // Merge with additional headers if provided
    };

    // Log the request for debugging
    console.log('Request URL:', url);
    console.log('Request Headers:', headers);
    console.log('Request Options:', options);

    // Execute the Axios request and return the full response
    const response = await axios({
      url,
      headers,
      ...options, // Include other options like method, data, params, etc.
    });

    return response; // Return the full Axios response
  } catch (error) {
    // Handle and log API errors
    console.error('Error en la solicitud API:', error.response || error.message);

    // Rethrow the error for the caller to handle
    throw error;
  }
};

export default apiClient;
