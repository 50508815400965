import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../apiClient';

const EditQuestion = () => {
  const { id } = useParams(); // Obtener el ID desde la URL
  const navigate = useNavigate(); // Para redirigir al usuario después de editar
  const [questionText, setQuestionText] = useState('');
  const [answers, setAnswers] = useState([
    { answerText: '', correct: false },
    { answerText: '', correct: false },
    { answerText: '', correct: false },
  ]);
  const [imageUrl, setImageUrl] = useState('');
  const [permission, setPermission] = useState('');
  const [temary, setTemary] = useState('');
  const [loading, setLoading] = useState(false);
  const getQuestionByIdUrl = process.env.REACT_APP_GET_QUESTION_BY_ID;
  const updateQuestionUrl = process.env.REACT_APP_UPDATE_QUESTION;

  // Cargar la pregunta existente desde el backend
  useEffect(() => {
    apiClient(`${getQuestionByIdUrl}/${id}`) // Fetch question by ID
      .then((data) => {
        // Update state with fetched data
        setQuestionText(data.questionText || '');
        setAnswers(data.answers || [
          { answerText: '', correct: false },
          { answerText: '', correct: false },
          { answerText: '', correct: false },
        ]);
        setImageUrl(data.imageUrl || '');
        setPermission(data.permission || '');
        setTemary(data.temary || '');
      })
      .catch((error) => {
        console.error('Error al cargar la pregunta:', error);
      });
  }, [id]);


  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index].answerText = value;
    setAnswers(updatedAnswers);
  };

  const handleCorrectChange = (index) => {
    const updatedAnswers = answers.map((answer, i) => ({
      ...answer,
      correct: i === index,
    }));
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !questionText ||
      !imageUrl ||
      !permission ||
      !temary ||
      answers.some(answer => !answer.answerText)
    ) {
      alert('Por favor completa todos los campos.');
      return;
    }

    setLoading(true);

    const updatedQuestion = {
      questionText,
      answers,
      imageUrl,
      permission,
      temary,
    };

    try {
      await apiClient(`${updateQuestionUrl}/${id}`, {
        method: 'PUT',
        data: updatedQuestion, // Use `data` for the request body
      });

      // Check the response directly
      navigate('/questions'); // Redirect to the questions list
    } catch (error) {
      console.error('Error de conexión:', error);
      alert('Error al actualizar la pregunta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-center mb-4">Editar Pregunta</h2>

      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-4 shadow-md rounded-md">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Texto de la pregunta</label>
          <input
            type="text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Escribe la pregunta"
          />
        </div>

        {answers.map((answer, index) => (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Respuesta {index + 1}
            </label>
            <input
              type="text"
              value={answer.answerText}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder={`Respuesta ${index + 1}`}
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  name="correctAnswer"
                  checked={answer.correct}
                  onChange={() => handleCorrectChange(index)}
                  className="mr-2"
                />
                Marcar como correcta
              </label>
            </div>
          </div>
        ))}

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">URL de la imagen</label>
          <input
            type="text"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Ingresa la URL de la imagen"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Permiso</label>
          <select
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Selecciona un permiso</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Temario</label>
          <input
            type="text"
            value={temary}
            onChange={(e) => setTemary(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Escribe el temario"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Guardar Cambios'}
        </button>
      </form>
    </div>
  );
};

export default EditQuestion;
